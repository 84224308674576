@import "mixins";

.layout {
  display: grid;

  @include desktop {
    // grid-template-columns: 220px 1fr;
    grid-template-rows: auto var(--header-height) 1fr;
    grid-template-areas:
      "banner"
      "header"
      "main";
  }

  @include mobile {
    grid-template-rows: auto repeat(1, var(--header-height)) 1fr;
    grid-template-areas:
      "sidebar"
      "header"
      "main";
  }

  height: 100vh;
}

.banner {
  grid-area: banner;
  -webkit-app-region: drag;
}

.sidebar {
  grid-area: sidebar;
  grid-template-rows: 1fr auto;

  display: grid;
  background: var(--menu-bg);
  overflow: hidden;
}

@mixin border-left {
  @include desktop {
    border-left: var(--border-width) solid var(--card-border);
  }
}

.header {
  @include border-left;

  grid-area: header;
  display: grid;

  background: var(--card-bg);
  gap: 10px;
  padding: 0 var(--main-padding-horizontal);
  position: relative;
  white-space: nowrap;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 55;

  .wrapper {
    @include flex(flex-end);
    gap: 10px;
    width: 100%;
    height: var(--header-height);
  }

  -webkit-app-region: drag;

  button {
    -webkit-app-region: no-drag;
  }
}

.main {
  @include border-left;

  grid-area: main;
  // overflow: auto;
  border-top: var(--border-width) solid var(--card-border);
  position: relative; // for progress bar from the page
}

/* menu open */
@include mobile {
  .menu {
    grid-template-rows: auto 1fr;
    grid-template-areas: "banner" "sidebar";

    .header,
    .main {
      display: none;
    }
  }
}
