@import "mixins";

.actions {
  flex-wrap: wrap;
  margin: 0 -6px;
  // gap: 12px;
}

.end {
  @include flex(flex-end);
}

.center {
  @include flex;
}

.stretch {
  @include flex(stretch);
  > * {
    flex: 1;
    margin: 6px;
  }
}
