.amount {
  font-size: 22px;
  font-weight: var(--bold);
}

.small,
.value {
  color: var(--text-muted);
  font-size: var(--font-size-small);
}
.card {
  background-color: transparent;
  border: none;
  padding: 0;
}
