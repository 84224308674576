/* color */
$colors: (
  info: var(--info),
  success: var(--success),
  warning: var(--warning),
  danger: var(--danger),
);

@each $color, $value in $colors {
  .#{$color} {
    color: $value;
  }

  .bg-#{$color} {
    background: $value;
  }

  .border-#{$color} {
    border: var(--border-width) solid $value;
  }
}

.fill-primary-dark {
  fill: hsl(
    var(--button-primary-bg-h),
    var(--button-primary-bg-s),
    calc(var(--button-primary-bg-l) - 5%)
  );
}

.fill-primary-light {
  fill: hsl(
    var(--button-primary-bg-h),
    var(--button-primary-bg-s),
    calc(var(--button-primary-bg-l) + 20%)
  );
}

/* text */
.center {
  text-align: center;
}

.muted {
  color: var(--text-muted);
}

.small {
  font-size: var(--font-size-small);
}
.break-all {
  word-break: break-all;
}
.delegate-switch{
  .MuiSwitch-track{
    background-color: #5c5fff;
    opacity: 0.5;
  }
  .MuiSwitch-thumb{
    color: #5c5fff;
  }
  .switch-wallet,.switch-node{
    font-size: 10px;
    color: var(--text-muted)
  }
}